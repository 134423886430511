import React, { useState } from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosArrowDown,
  IoIosCheckmark,
  IoIosArrowForward,
  IoMdOpen,
} from "react-icons/io";

import { useModal } from "../../components/useModal";

import Image from "../../components/image";

import { Loader, Success } from "../../components/feedback";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../../components/util";
import {
  AuthorCard,
  SmallResourceCard,
  Breadcrumb,
  Modal,
} from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const WebinarCornerPic = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1000px;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const Form = styled(Box)`
  z-index: 2;
  padding: 30px;
  text-align: center;
  background-color: ${(props) => props.theme.colours.white};
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;
  }

  h2 {
    color: ${(props) => props.theme.colours.dark} !important;
  }

  form {
    margin-bottom: 0;
  }
`;

// const CardRow = styled(Row)`
//   width: calc(100% + 30px);
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     width: 100%;
//   }
// `;

const UntanglingHolidaysAct = (props) => {
  let [success, setForm] = useState(false);
  let [loading, setFormLoading] = useState(false);

  const [newsletter, setNewsletter] = useState(false);

  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const handleCheckboxChange = (event) => {
    setNewsletter(event.target.checked);
  };

  const handleSubmit = () => {
    setFormLoading(true);
    setTimeout(function() {
      setForm(true);
      setFormLoading(false);
    }, 2000);
  };

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: DESC, fields: publishedDate }
        filter: {
          resourceTitle: {
            in: [
              "How compliant is your payroll? Questions to ask yourself (or your payroll provider)."
              "What is Payroll Compliance?"
              "Understanding New Zealand’s payroll compliance nightmare"
            ]
          }
        }
      ) {
        edges {
          node {
            resourceImage {
              gatsbyImageData(width: 75, layout: FIXED, quality: 100)
            }
            resourceTitle
            resourceType
            resourceTopic
            url
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Untangling Holidays Act compliance with PayHero | PayHero Webinar"
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="CA ANZ"
        parentPageLink="/ca"
        currentPage="Untangling Holidays Act compliance with PayHero"
      />
      <Container className="primary" css={{ paddingTop: "15px" }}>
        <Wrapper stackGap={60}>
          <Row stackGap={80} alignCenter>
            <Box stackGap={40} size={60} css={{ zIndex: "2" }} centerOnMobile>
              <h4 className="badge -lg -dark">On Demand Webinar</h4>
              <h1 className="-fontLarge">
                Untangling Holidays Act compliance with PayHero
              </h1>
              <Image
                filename="CAANZ_PayHero_Horiz.png"
                alt="Untangling Holidays Act compliance with PayHero"
                maxWidth={350}
                width="60%"
                centerOnMobile
              />
            </Box>
            <Form size={40}>
              <Box stackGap={30}>
                <iframe
                  name="stopRedirect"
                  id="stopRedirect"
                  css={{ display: "none" }}
                  title="PayHero | Submit Form"
                ></iframe>
                <Loader show={loading} />
                <h2 css={{ marginTop: "10px" }}>Request Webinar</h2>
                {!success && (
                  <>
                    <form
                      className={`${loading ? "-isLoading" : ""} -smallStack`}
                      data-drip-embedded-form="38943742"
                      action="https://www.getdrip.com/forms/38943742/submissions"
                      target="stopRedirect"
                      method="POST"
                      onSubmit={handleSubmit}
                    >
                      <input
                        placeholder="First Name"
                        type="text"
                        name="fields[first_name]"
                        required
                      />
                      <input
                        placeholder="Last Name"
                        type="text"
                        name="fields[last_name]"
                        required
                      />
                      <input
                        placeholder="Email Address"
                        type="email"
                        name="fields[email]"
                        required
                      />
                      <input
                        placeholder="Company Name"
                        type="text"
                        name="fields[company_name]"
                        required
                      />
                      <input
                        placeholder="Contact Number"
                        type="text"
                        name="fields[contact_number]"
                        required
                      />
                      <div className="select-wrapper">
                        <select
                          name="fields[region]"
                          aria-label="Select Region"
                          defaultValue=""
                          required
                        >
                          <option value="" disabled>
                            Select Region...
                          </option>
                          <option value="Auckland">Auckland</option>
                          <option value="Bay of Plenty">Bay of Plenty</option>
                          <option value="Canterbury">Canterbury</option>
                          <option value="Chatam Islands">Chatam Islands</option>
                          <option value="Gisborne">Gisborne</option>
                          <option value="Hawke's Bay">Hawke's Bay</option>
                          <option value="Manawatu - Whanganui">
                            Manawatu - Whanganui
                          </option>
                          <option value="Marlborough">Marlborough</option>
                          <option value="Nelson">Nelson</option>
                          <option value="Northland">Northland</option>
                          <option value="Otago">Otago</option>
                          <option value="Southland">Southland</option>
                          <option value="Taranaki">Taranaki</option>
                          <option value="Tasman">Tasman</option>
                          <option value="Waikato">Waikato</option>
                          <option value="Wellington">Wellington</option>
                          <option value="West Coast">West Coast</option>
                        </select>
                        <IoIosArrowDown className="dropdown-arrow -dark" />
                      </div>
                      <input
                        type="text"
                        name="fields[newsletter_signup]"
                        value={newsletter}
                        hidden
                      />
                      <div className="checkbox-wrapper">
                        <label>
                          <div>
                            <input
                              aria-label="Sign up to our newsletter | PayHero"
                              type="checkbox"
                              onChange={handleCheckboxChange}
                            />
                            <IoIosCheckmark className="checkmark" />
                          </div>
                          <p
                            css={{
                              marginBottom: "0",
                              color: "#333 !important",
                              top: "-2px",
                              position: "relative",
                            }}
                          >
                            Sign up to our newsletter
                          </p>
                        </label>
                      </div>
                      <button className="darkBlue">Request Webinar</button>
                    </form>
                  </>
                )}
                <Success
                  show={success}
                  title="Webinar Requested"
                  message="Thanks! A copy of the webinar is on its way to your inbox."
                  centerAlign
                />
              </Box>
            </Form>
          </Row>
        </Wrapper>
        <WebinarCornerPic>
          <Image
            filename="WebinarCornerPic.png"
            alt="Untangling Holidays Act Webinar"
          />
        </WebinarCornerPic>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} mobileReverse>
            <Box size={30} stackGap={45}>
              <Box stackGap={25}>
                <h4 className="-fontBold">Hosted by:</h4>
                <AuthorCard author="Rob" />
                <AuthorCard author="Sam" />
              </Box>
              <hr />
              {/* <Box stackGap={10}>
                <h4 className="-fontBold">Date & Time:</h4>
                <h4>
                  <span className="-fontBold">1:00pm, </span>
                  <span className="-fontNormal">Monday 13 October,</span> 2020
                </h4>
              </Box>
              <hr /> */}
              <Box stackGap={25}>
                <h4 className="-fontBold">Related Resources:</h4>
                {contentfulData.allContentfulResource.edges.map((edge, i) => {
                  return (
                    <SmallResourceCard
                      image={
                        edge.node.resourceImage &&
                        edge.node.resourceImage.gatsbyImageData
                      }
                      url={edge.node.url}
                      type={edge.node.resourceType}
                      title={edge.node.resourceTitle}
                      key={i}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box size={70} stackGap={60}>
              <Box stackGap={25}>
                <h2>Untangling Holidays Act compliance with PayHero</h2>
                <h4>
                  Widespread examples of Holidays Act calculation errors have
                  hit the news in recent years, with large organisations and
                  government agencies paying out hundreds of millions of dollars
                  to cover employee holiday underpayments.
                </h4>
                <h4>
                  With MYOB recently notifying customers of problems with how
                  its NZ payroll products calculate employee leave, the Holidays
                  Act compliance nightmare is now firmly on the radar for small
                  and medium businesses.
                </h4>
                <h4>
                  Up and down the country SME clients have been increasingly
                  throwing all sorts of tricky questions at their accountants as
                  they try to figure out whether they could be affected.
                </h4>
                <h4>
                  Join us for this informative webinar explaining exactly what’s
                  going on, why the standard payroll system approach to accruing
                  leave doesn’t work, and how to handle variable hours, changing
                  work patterns, and tricky public holiday calculations.
                </h4>
                <h4>
                  Learn how to answer the most common leave and payroll
                  compliance questions and help your clients move forward with
                  the confidence they’re paying their employees right.
                </h4>
              </Box>
              <Box stackGap={25}>
                <h4 className="-fontBold">This webinar covers:</h4>
                <ul>
                  <li>
                    <h4>The most common Holidays Act compliance issues</h4>
                  </li>
                  <li>
                    <h4>
                      The types of companies and employees most likely to be
                      affected
                    </h4>
                  </li>
                  <li>
                    <h4>
                      The impact of COVID-19 on payroll and leave calculations
                    </h4>
                  </li>
                  <li>
                    <h4>What you and your clients can do to reduce risk</h4>
                  </li>
                  <li>
                    <h4>
                      The Holidays Act review, what’s next for NZ payroll & what
                      that means for your practice
                    </h4>
                  </li>
                  <li>
                    <h4>
                      How accountants can embrace the payroll compliance
                      opportunity
                    </h4>
                  </li>
                </ul>
              </Box>
              <hr />
              <Box stackGap={25}>
                <h4 className="-fontBold">About PayHero</h4>
                <h4>
                  PayHero is perfect for Kiwi companies that want stress free
                  and compliant online payroll. Especially those with part time
                  and mobile workforces, who face unique challenges tracking
                  employee time accurately and calculating the right holiday
                  entitlements for their staff.
                </h4>
                <h4>
                  PayHero streamlines the employee timesheet process with
                  online, mobile, GPS or clock in/out options delivering
                  accurate employee hours straight into payroll.
                </h4>
                <h4 className="-fontBold">
                  <Link className="link-arrow" to="/ca">
                    Find out more <IoIosArrowForward />
                  </Link>
                </h4>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container className="-textCenter secondary">
        <Wrapper stackGap={80}>
          <Box stackGap={40}>
            <h2>
              Compliant payroll you and your clients can trust
              <br />
              <span className="-fontLight">
                Find out more about PayHero or our partner programme.
              </span>
            </h2>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default UntanglingHolidaysAct;
